export const actions = [
    'ViewDashboard',

    'ListBankAccounts',
    'GetBankAccount',
    'CreateBankAccount',
    'UpdateBankAccount',
    'DeleteBankAccount',

    'ListCards',
    'GetCard',
    'CreateCard',
    'UpdateCard',
    'DeleteCard',

    'ListInvoices',
    'GetInvoice',
    'CreateInvoice',
    'UpdateInvoice',
    'DeleteInvoice',

    'ListCustomers',
    'GetCustomer',
    'CreateCustomer',
    'UpdateCustomer',
    'DeleteCustomer',

    'ListProducts',
    'GetProduct',
    'CreateProduct',
    'UpdateProduct',
    'DeleteProduct',

    'ListSubscriptions',
    'GetSubscription',
    'CreateSubscription',
    'UpdateSubscription',
    'DeleteSubscription',

    'ListPrices',
    'GetPrice',
    'CreatePrice',
    'UpdatePrice',
    'DeletePrice',

    'ListInvoiceItem',
    'GetInvoiceItem',
    'CreateInvoiceItem',
    'UpdateInvoiceItem',
    'DeleteInvoiceItem',

    'ListSubscriptionItem',
    'GetSubscriptionItem',
    'CreateSubscriptionItem',
    'UpdateSubscriptionItem',
    'DeleteSubscriptionItem',


];

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);