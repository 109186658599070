import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    FlexiAddButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {
    Box,
    Title,
} from '@mantine/core';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {useBankAccounts} from '../hooks/useBankAccounts.ts';
import PropTypes from 'prop-types';
import {AiOutlineBank} from 'react-icons/ai'
import {useBankAccountsSelection} from "../hooks/useBankAccountsSelection.ts";
import {useBankAccount} from "../hooks/useBankAccount.ts";


import {openModal,} from '@mantine/modals';


import {RecordForm} from "../forms/RecordForm.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";


const ComponentsReloadButton = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {fetchRecords} = useBankAccounts(customerId);
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add Bank Account',
                             size = 'sm',
                             onClick,
                         }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }


    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate(`/customers/${customerId}/bank-accounts/add`);
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};


export const AddRecordButtonNew = () => {


    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter');
    }

    const {createRecord} = useBankAccount(customerId);


    const onClickHandler = () => openModal({
                                               title: <Title order={3}>Add Bank Account</Title>,
                                               children: (
                                                   <RecordForm
                                                       handleSubmit={(values) => {
                                                           createRecord(values);
                                                           //closeAllModals();
                                                       }}
                                                       record={{
                                                           customer: customerId,
                                                       }}
                                                   />
                                               ),
                                           });


    return (
        <FlexiAddButton onClick={onClickHandler}>Add Bank Account</FlexiAddButton>
    );
};


const DeleteRecordButton = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {deleteRecords} = useBankAccounts(customerId);

    const {
        selection,
        handleClearSelection,
    } = useBankAccountsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (allowedActions.includes('DeleteBankAccount')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes('CreateBankAccount')) {
        tools.push(<AddRecordButton key='add'/>);
    }

    // if (allowedActions.includes('CreateBankAccount')) {
    //     tools.push(<AddRecordButtonNew key='add-new'/>);
    // }

    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Bank Accounts')}
            icon={<AiOutlineBank/>}
            tools={useTools()}
        />
    );
};

AddRecordButton.propTypes = {
    buttonText: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
};