import Stripe from "./stripe";
import platform from "./platform";
import {stage} from './stage';
import kinesis from './kinesis.js';
import permissions from "./permissions.ts";


const config = {
    Stripe,
    platform,
    stage,
    kinesis,
    appTitle: "Flexi Network Portal",
    appSubTitle: "Flexi Network Portal Account",
    appLogoUrl: "https://s3.amazonaws.com/cdn.flexi.network/flexi.domains/images/logo/flexidomains_logo.png",
    permissions
};

export default config;
