import {
    Grid,
    Stack,
} from '@mantine/core';
import {CustomersWidget} from './dashboard/CustomersWidget.tsx';
import {SubscriptionsWidget} from "./dashboard/SubscriptionsWidget.tsx";
import {PricesWidget} from "./dashboard/PricesWidget.tsx";
import {InvoicesWidget} from "./dashboard/InvoicesWidget.tsx";
import {ProductsWidget} from "./dashboard/ProductsWidget.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalDashboard = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Grid mx={'10%'}>
            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    {allowedActions.includes('ListCustomers') ? <CustomersWidget/> : null}
                    {allowedActions.includes('ListInvoices') ? <InvoicesWidget/> : null}
                    {allowedActions.includes('ListProducts') ? <ProductsWidget/> : null}
                </Stack>
            </Grid.Col>

            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    {allowedActions.includes('ListSubscriptions') ? <SubscriptionsWidget/> : null}
                    {allowedActions.includes('ListPrices') ? <PricesWidget/> : null}
                </Stack>
            </Grid.Col>
        </Grid>
    );
};
