import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    FlexiAddButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {
    Box,
    Title,
} from '@mantine/core';

import {useParams} from 'react-router-dom';

import {useInvoiceItems} from '../hooks/useInvoiceItems.ts';
import {AiOutlineBank} from 'react-icons/ai'
import {useInvoiceItemsSelection} from "../hooks/useInvoiceItemsSelection.ts";
import {useInvoiceItem} from "../hooks/useInvoiceItem.ts";


import {openModal,} from '@mantine/modals';


import {RecordForm} from "../forms/RecordForm.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";


const ComponentsReloadButton = () => {

    const {id: invoiceId} = useParams();

    if (typeof invoiceId === 'undefined') {
        throw new Error('invoiceId is required parameter')
    }

    const {fetchRecords} = useInvoiceItems(invoiceId);
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

export const AddRecordButton = () => {


    const {id: invoiceId} = useParams();

    if (typeof invoiceId === 'undefined') {
        throw new Error('invoiceId is required parameter');
    }

    const {createRecord} = useInvoiceItem(invoiceId);


    const onClickHandler = () => openModal({
                                               title: <Title order={3}>Add Invoice Item</Title>,
                                               children: (
                                                   <RecordForm
                                                       handleSubmit={(values) => {
                                                           createRecord(values);
                                                           //closeAllModals();
                                                       }}
                                                       record={{
                                                           invoice: invoiceId,
                                                       }}
                                                   />
                                               ),
                                           });


    return (
        <FlexiAddButton onClick={onClickHandler}>Add Invoice Item</FlexiAddButton>
    );
};


const DeleteRecordButton = () => {

    const {id: invoiceId} = useParams();

    if (typeof invoiceId === 'undefined') {
        throw new Error('invoiceId is required parameter')
    }

    const {deleteRecords} = useInvoiceItems(invoiceId);

    const {
        selection,
        handleClearSelection,
    } = useInvoiceItemsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (allowedActions.includes('DeleteInvoiceItem')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes('CreateInvoiceItem')) {
        tools.push(<AddRecordButton key='add'/>);
    }

    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Invoice Items')}
            icon={<AiOutlineBank/>}
            tools={useTools()}
        />
    );
};

