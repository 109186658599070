import {stage} from './stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        enabled: boolean;
        policyStoreId: string;
        region: string;
    };
};

const config: ConfigShape = {
    prod: {
        enabled: true,
        policyStoreId: 'JEAtTRXwgC8UjwMcSf2Pfj',
        region: 'us-west-2',
    },
    test: {
        enabled: true,
        policyStoreId: 'Kdn1t6osZSdUHXwA9LBocA',
        region: 'us-west-2',
    },
    dev: {
        enabled: true,
        policyStoreId: 'X6h1hLQkGS3tWQvyze54TS',
        region: 'us-west-2',
    },
    ide: {
        enabled: true,
        policyStoreId: 'X6h1hLQkGS3tWQvyze54TS',
        region: 'us-west-2',
    },
};

export default config[stage];
